import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceBG2BrigshawGarforthPage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BG2 (Brigshaw School & Garforth Academy) AM Only"
          description="View route information and buy your ticket for the BG2 (Brigshaw School & Garforth Academy) service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BG2 service - AM ONLY</h1>
              <p>Goody Cross Lane/The Grove – Brigshaw School (Via Garforth Academy)</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="BG2 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=181vtYjqZKQZRZwiCNSKdU1wD-BsjYqI&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Garforth Academy/Brigshaw School
                </p>
                <p>
                  From Queen Street/Bowers Row via Queen Street, Astley Lane, Wakefield Road, Swillington Lane, Neville Grove via Neville Grove, Church Lane, Wakefield Road, Goody Cross Lane, Whitehouse Lane, Wakefield Road, Selby Road, Leeds Road, Cross Hills, Butt Hill and Brigshaw Lane.
                </p>


              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Queen Street/Bowers Row</td>
                      <td>DEP</td>
                      <td>0730</td>
                    </tr>
                    <tr>
                      <td>Astley Lane/Park Grove</td>
                      <td>DEP</td>
                      <td>0732</td>
                    </tr>
                    <tr>
                      <td>Neville Grove/Whitcliffe Crescent</td>
                      <td>DEP</td>
                      <td>0743</td>
                    </tr>
                    <tr>
                      <td>Goody Cross Lane / The Grove</td>
                      <td>DEP</td>
                      <td>0748</td>
                    </tr>
                    <tr>
                      <td>Garforth Academy</td>
                      <td>ARR</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>ARR</td>
                      <td>0815</td>
                    </tr>

                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceBG2BrigshawGarforthPage
